.reviews {
    padding-top: 80px;
    padding-bottom: 69px;
    background-color: #FFF4F9;
    @media (max-width: 768px) {
            padding-top: 40px;
            padding-bottom: 40px;
         }
    &__title {
            font-weight: 600;
            font-size: 36px;
            line-height: 130%;
            color: #2D1B53;
            max-width: 415px;
            margin-bottom: 64px;
            @media (max-width: 992px) {
                   margin-right: 0;
                   margin-bottom: 24px;
                   max-width: 100%;
                   text-align: center;
                }
                @media (max-width: 769px) {
                    font-size: 22px;
                 }
            &::after {
                    content: "";
                    display: block;
                    width: 100px;
                    height: 2px;
                    background-color: #FF4A9B;
                    border-radius: 10px;
                    margin-top: 4px;
                    @media (max-width: 992px) {
                            margin: 0 auto;
                         }
            }
    }
    &__grid {
            display: grid;
            gap: 30px;
            grid-template-columns: repeat(3, 1fr);
            @media (max-width: 992px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media (max-width: 640px) {
                    grid-template-columns: repeat(1, 1fr);
                }
    }
    &__btn {
            margin-top: 30px;
            background-color: transparent;
            font-weight: 500;
            font-size: 22px;
            line-height: 27px;
            text-decoration-line: underline;
            color: #FF4A9B;
            @media (max-width: 768px) {
                    font-size: 18px;
            }
    }
}


.card {
    padding: 24px 24px 40px 24px;
    background-color: #FFFFFF;
    border-radius: 18px;
    max-width: 350px;
    min-width: 100%;
    box-sizing: border-box;
    filter: drop-shadow(0px 10px 20px rgba(184, 183, 227, 0.3));
    
    @media (max-width: 480px) {
            padding: 15px 15px 40px 15px;
        }
    &__header {
            display: flex;
            margin-bottom: 16px;
    }

    &__ava {
            width: 85px;
            height: 85px;
            border-radius: 50%;
            box-shadow: 0px 10px 20px rgba(183, 180, 223, 0.5);
            margin-right: 16px;
            @media (max-width: 480px) {
                    width: 70px;
                    height: 70px;
                    margin-right: 8px;
                }
    }
    &__info {
            & h4 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 150%;
                    color:#2D1B53;
                    margin-bottom: 4px;
                    @media (max-width: 480px) {
                           font-size: 18px;
                        }
            }
            & span {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #8775AE;
                    @media (max-width: 480px) {
                            font-size: 14px;
                         }
            }
    }
    
    &__body {
        box-sizing:border-box;
            & p {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #222222;
                    margin-bottom: 16px;
                    @media (max-width: 480px) {
                            font-size: 15px;
                         }
            }
    }

    &__btn {
            outline: none;
            background-color: transparent;
            font-weight: 400;
            font-size: 15px;
            line-height: 150%;
            text-decoration-line: underline;
            color: #656565;
    }
}
