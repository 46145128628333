.gratitude {
	padding-top: 60px;
	padding-bottom: 100px;
	background: radial-gradient(
			54.27% 95.59% at 100% 100%,
			rgba(146, 140, 220, 0.3) 0%,
			rgba(159, 155, 227, 0) 100%
		),
		transparent;
	@media (max-width: 768px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	&__container {
		display: flex;
		justify-content: space-between;
		@media (max-width: 992px) {
			flex-direction: column;
			align-items: center;
		}
	}
	&__title {
		margin-right: 195px;
		font-weight: 600;
		font-size: 36px;
		line-height: 130%;
		color: #2d1b53;
		max-width: 415px;
		@media (max-width: 992px) {
			margin-right: 0;
			margin-bottom: 38px;
			max-width: 100%;
		}
		@media (max-width: 768px) {
			font-size: 22px;
		}
		&::after {
			content: '';
			display: block;
			width: 100px;
			height: 2px;
			background-color: #ff4a9b;
			border-radius: 10px;
			margin-top: 4px;
			@media (max-width: 992px) {
				margin: 0 auto;
			}
		}
	}
}

.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	& label {
		display: flex;
		flex-direction: column;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: #8775ae;
		@media (max-width: 480px) {
			font-size: 12px;
		}
	}
	&__name {
		border: 1px solid #8775ae;
		border-radius: 30px;
		padding: 15px 16px;
		margin-top: 16px;
		margin-bottom: 32px;
		background: transparent;
		align-self: flex-start;
		width: 255px;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: #222222;
		@media (max-width: 480px) {
			width: 100%;
			padding: 12px 16px;
			margin-bottom: 16px;
			margin-top: 4px;
			font-size: 15px;
		}
	}
	&__textarea {
		padding: 20px 16px;
		border: 1px solid #8775ae;
		border-radius: 30px;
		resize: none;
		max-width: 635px;
		margin-top: 16px;
		margin-bottom: 32px;
		background: transparent;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: #222222;
		max-height: 198px;
		white-space: pre-wrap;
		overflow-wrap: break-word;
		@media (max-width: 480px) {
			max-height: 301px;
			padding: 16px;
			margin-bottom: 16px;
			margin-top: 4px;
			font-size: 15px;
		}
	}
	&__btn {
		align-self: flex-start;
		background: #ff4a9b;
		border-radius: 30px;
		font-weight: 700;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: #ffffff;
		padding: 19px 76px;
		margin-top: 16px;
		&:disabled {
			background: lightgray;
		}
		@media (max-width: 480px) {
			width: 100%;
		}
	}
}
