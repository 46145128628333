.about {
	padding-top: 40px;
	padding-bottom: 110px;
	background: radial-gradient(
			43.72% 65.14% at 100% 100%,
			rgba(146, 140, 220, 0.3) 0%,
			rgba(159, 155, 227, 0) 89.25%
		),
		#fff4f9;
	@media (max-width: 768px) {
		padding-bottom: 57px;
	}
	&__container {
		display: flex;
		justify-content: space-between;
		@media (max-width: 992px) {
			flex-direction: column;
			align-items: center;
		}
	}
	&__title {
		font-weight: 600;
		font-size: 36px;
		line-height: 130%;
		color: #2d1b53;
		max-width: 415px;
		margin-bottom: 64px;
		@media (max-width: 992px) {
			margin-right: 0;
			margin-bottom: 24px;
			max-width: 100%;
			text-align: center;
		}
		@media (max-width: 768px) {
			font-size: 22px;
		}
		&::after {
			content: '';
			display: block;
			width: 100px;
			height: 2px;
			background-color: #ff4a9b;
			border-radius: 10px;
			margin-top: 4px;
			@media (max-width: 992px) {
				margin: 0 auto;
			}
		}
	}
	&__info {
		max-width: 635px;
		& p {
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #222222;
			padding-bottom: 16px;
			@media (max-width: 768px) {
				font-size: 15px;
			}
		}
	}
}
