.reviews-counter {
	padding-top: 32px;
	padding-bottom: 32px;
	background: url('../../../public/images/bg-counter.png') no-repeat center;
	background-size: cover;
	filter: drop-shadow(0px 10px 30px rgba(255, 74, 155, 0.2));
	@media (max-width: 768px) {
		background: url('../../../public/images/bg-counter-mobile.png') no-repeat center;
		background-size: cover;
	}
	&__container {
		background: url('../../../public/images/bg-counter2.png') no-repeat center;
		background-size: contain;
	}
	&__title {
		text-align: center;
		font-weight: 600;
		font-size: 36px;
		line-height: 44px;
		color: #2d1b53;
		margin-bottom: 24px;
		@media (max-width: 768px) {
			font-size: 22px;
		}
	}
}

.reviews-counts {
	display: flex;
	justify-content: center;
	gap: 15px;
	&__item {
		padding: 5px 7px;
		font-weight: 600;
		font-size: 60px;
		line-height: 73px;
		color: #ff4a9b;
		background: #ffffff;
		border-radius: 12px;
		filter: drop-shadow(0px 10px 20px rgba(239, 53, 148, 0.2));
		width: 55px;
	}
}
