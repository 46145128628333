.hero {
	background: radial-gradient(
			57.6% 260.62% at -0.28% 99.56%,
			rgba(255, 255, 255, 0.75) 0%,
			rgba(255, 255, 255, 0) 100%
		),
		url(../../../public/images/hero_image_1440.jpg) no-repeat center;
	background-size: cover;
	box-shadow: 0px 16px 20px rgba(120, 112, 232, 0.08);
	padding-top: 145px;
	@media (max-width: 992px) {
		background: radial-gradient(
				57.6% 260.62% at -0.28% 99.56%,
				rgba(255, 255, 255, 0.75) 0%,
				rgba(255, 255, 255, 0) 100%
			),
			url(../../../public/images/hero_image_320.jpg) no-repeat center;
		background-size: cover;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	&__container {
		display: flex;
		justify-content: space-between;
		@media (max-width: 992px) {
			flex-direction: column-reverse;
			align-items: center;
			text-align: center;
		}
	}
	&__text {
		& h1 {
			font-weight: 700;
			font-size: 52px;
			line-height: 130%;
			letter-spacing: 0.02em;
			text-transform: uppercase;
			color: #2d1b53;
			max-width: 540px;
			margin-bottom: 40px;
			@media (max-width: 768px) {
				font-size: 36px;
				max-width: 290px;
				margin-bottom: 24px;
			}
		}
	}
	&__img img {
		max-width: 413px;
		max-height: 524px;
		min-width: 413px;
		min-height: 524px;

		@media (max-width: 992px) {
			margin-bottom: 24px;
			min-width: 204px;
			min-height: 260px;
			padding-left: 36px;
			padding-right: 36px;
		}
	}
}

.likes {
	display: flex;
	flex-direction: column;
	@media (max-width: 992px) {
		align-items: center;
	}
	&__counter {
		margin-top: 8px;
		font-weight: 300;
		font-size: 24px;
		line-height: 150%;
		color: #2d1b53;
		@media (max-width: 992px) {
			margin-top: 20px;
		}
	}
	&__btn{
		background: transparent;
		align-self: flex-start;
		@media (max-width: 992px) {
			align-self: center;
		}
	}
}
