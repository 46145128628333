// Header
header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-width: 320px;
}

// Navbar
nav {
    background-color: transparent;
    overflow: hidden;
}

.navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 15px;
    @media (max-width: 992px) {
        padding-top: 20px;
        justify-content: space-between;
        align-items: center;
    }
}

.logo-navbar {
    display: none;
    @media screen and (max-width: 992px) {
        display: block;
    }
}

.menu {
    display: flex;

    // .menu__item
    &__item {
        margin-right: 56px;
        @media (max-width: 992px) {
            margin-right: 0;
        }
        &::after {
            content: '';
            display: block;
            width: 0;
            height: 2px;
            background-color: #ff4a9b;
            border-radius: 10px;
            margin-top: 4px;
            transition: all 0.2s;
        }
        &:hover::after {
            width: 47px;
        }
        &:focus::after {
            content: '';
            display: block;
            width: 47px;
            height: 2px;
            background-color: #ff4a9b;
            border-radius: 10px;
            margin-top: 4px;
            transition: all 0.2s;
        }
    }

    // .menu__item-link
    &__item-link {
        cursor: pointer;
        color: #2d1b53;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
}

.burger {
    display: none;
    cursor: pointer;
}

// Burger menu styles
@media screen and (max-width: 992px) {
    // .menu
    .menu {
        position: fixed;
        background: radial-gradient(63.44% 97.36% at 100% 100%, rgba(146, 140, 220, 0.3) 0%, rgba(159, 155, 227, 0) 100%),
            radial-gradient(324.11% 299.61% at 0% 0%, rgba(255, 123, 198, 0.2) 0%, rgba(254, 121, 180, 0) 100%), #ffffff;
        left: 0;
        top: 0;
        height: 100vh;
        width: 100vw;
        display: none;

        &.active {
            display: flex;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100%;
            flex-direction: column;
            pointer-events: all;
            justify-content: flex-start;
            padding-top: 60px;
            align-items: center;
            z-index: 22;
            animation: fade 0.1s linear;
        }
    }

    // .burger
    .burger {
        display: block;
        position: relative;
        height: 20px;
        width: 30px;
        z-index: 102;
        cursor: pointer;

        &.active-burger {
            &:before {
                background: rgba(255, 74, 155, 1);
                transform: rotate(45deg);
                transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
                top: 8px;
            }

            &:after {
                background: rgba(255, 74, 155, 1);
                transform: rotate(-45deg);
                bottom: 10px;
                transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
            }

            & span {
                transform: scale(0);
            }
        }

        &:before,
        &:after {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            background: #222222;
            transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
        }

        &:before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        span {
            position: absolute;
            top: 9px;
            right: 0;
            width: 100%;
            background: #222222;
            height: 2px;
            transform: scale(1);
            transition: cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.3s all;
        }
    }

    // .menu__item
    .menu__item {
        margin-bottom: 32px;
    }

    // .menu__link
    .menu__item-link {
        cursor: pointer;
        color: #2d1b53;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
}
.logo-header {
    margin-top: -18px;
    img {
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 992px) {
        width: 39px;
        height: 36px;
        margin-top: 0;
    }
}
