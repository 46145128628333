footer {
    background-color: #696bcc;
    padding-top: 60px;
    padding-bottom: 60px;
    @media (max-width: 480px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 565px) {
        flex-direction: column;
        gap: 24px;
    }
}
.footer-menu {
    display: flex;
    @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    &__item {
        margin-right: 56px;
        @media (max-width: 480px) {
            margin-right: 0;
        }
    }
    &__link {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
    }
}
