// ************************ Модальное окно ************************ //
// mf
.modal__wrapper {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.2);
	justify-content: center;
	align-items: center;
	z-index: 2;
	display: none;
	&.active {
		display: flex;
	}

	.modal {
		background: #fffcfd;
		padding: 100px 15px;
		border-radius: 18px;
		height: 501px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 32px;
		@media (max-width: 480px) {
			gap: 24px;
			padding: 40px 15px;
			height: 280px;
		}
		&__title {
			font-weight: 700;
			font-size: 36px;
			line-height: 44px;
			text-align: center;
			color: #172945;
			max-width: 648px;
			@media (max-width: 480px) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		&__img {
			@media (max-width: 480px) {
				width: 41px;
				height: 41px;
			}
		}

		&__btn {
			background: #ff4a9b;
			border-radius: 30px;
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
			letter-spacing: 0.02em;
			text-transform: uppercase;
			color: #ffffff;
			padding: 19px 120px;
			margin-top: 16px;
			border: none;
			&:disabled {
				background: lightgray;
			}
			@media (max-width: 480px) {
				width: 100%;
			}
		}
	}
}

// ************************ Кнопка вызова модального окна ************************ //
.modal__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid black;
	border-radius: 30px;
	height: 50px;
	width: 150px;
	cursor: pointer;
}
